$ng-select-highlight: #F1C716;
@import "@ng-select/ng-select/scss/material.theme";

ng-select.ng-select {
  width: 100%;
  padding-bottom: 0;

  &.ng-select-opened {
    .ng-placeholder {
      opacity: 1;
      margin-top: -5px;
      transition: opacity 150ms ease-out;
    }

    .ng-arrow-wrapper .ng-arrow {
      transform: rotate(180deg);
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          background-color: var(--ion-color-primary);
          padding: 3px 6px 4px 6px;

          .ng-value-icon {
            color: rgba(0, 0, 0, 0.7);

            &:hover {
              color: black;
            }
          }

          .ng-value-label {
            color: black;
            font-weight: 400;
          }
        }
      }
    }
  }

  .ng-select-container {
    min-height: 37px;

    .ng-value-container {
      .ng-value .wrap-text {
        white-space: normal !important;
      }
    }

    .ng-placeholder {
      opacity: 1;
      transition: opacity 150ms ease-out;
    }

    &.ng-has-value .ng-placeholder {
      opacity: 0;
    }

    .ng-clear-wrapper {
      display: none;
      bottom: 16px !important;
    }

    .ng-arrow-wrapper {
      display: none;
      bottom: 19px !important;

      .ng-arrow {
        color: rgba(0, 0, 0, 0.54) !important;
        transition: transform 150ms ease-out;

      }
    }

    &:after {
      border-bottom: none;
    }
  }

  .ng-value-container {
    border-top: none !important;
    padding-top: 9px !important;
  }
}

ng-dropdown-panel.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    font-size: 14px;

    .ng-option {
      &.ng-option-selected,
      &.ng-option-marked {
        background-color: var(--ion-color-primary);
        color: black;
      }

      .wrap-text {
        white-space: normal;
        line-height: 1.5;
        padding-top: 11px;
        padding-bottom: 10px;
      }
    }

  }
}

.blink-item-input {
  .input-select-action-button {
    margin: auto;

    ui-icon {
      color: rgba(0, 0, 0, 0.54) !important;
      transition: transform 150ms ease-out;
    }
  }

  overflow: visible;

  &.is-open {
    z-index: 999;

    .dropdown-chevron {
      transform: rotate(180deg);
    }
  }
}
