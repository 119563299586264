﻿
.spinner-container {
  height: 70%;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  justify-content: center;
}

.spinner-container-small {
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
