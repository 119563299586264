ion-button {
  font-weight: 400;
  letter-spacing: normal;
  text-transform: none;
  --box-shadow: none;
  --border-radius: 6px;
  margin: .4rem .2rem;

  &.button-outline {
    --border-width: 1px;
    --color: var(--ion-color-dark);

    &::part(native) {
      color: var(--ion-color-dark);
    }

    .button-native {
      color: var(--ion-color-dark);
    }

    &[color="primary"] {
      i, ui-icon {
        color: var(--ion-color-dark);
      }
    }
  }

  &.button-solid {
    &[color="success"],
    &[color="danger"] {
      i, ui-icon {
        color: white !important;
      }
    }
  }

  &.button-clear {
    color: var(--ion-color-dark);

    &:hover {
      --background: var(--item-background-hover);
    }

    i, ui-icon {
      //color: var(--ion-color-primary);
    }
  }
}

.button-outline.ion-color .button-native {
  color: var(--ion-color-dark);
}
