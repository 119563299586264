ion-app {
  background: var(--ion-background-color);

  #main-content {
    .ion-page {
      @media(max-width: 992px) {
        padding-bottom: 63px;
      }
    }
  }

  ion-menu {
    border-right: none;

    width: 272px;
    @media(max-width: 992px) {
      width: 100%;
    }
  }
}

ion-list {
  ion-item {
    i, ui-icon {
      margin-right: 0.5em;

      &[slot="icon-only"] {
        margin: 0;
        width: 20px;
      }
    }
  }
}

ion-popover {
  ion-item {
    i, ui-icon {
      margin-right: 1em;
    }
  }
}


.blink-input {
  padding: 0 !important;
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: var(--ion-color-input);


  label {
    font-size: 13px;

    input, textarea {
      font-size: 1rem !important;
      padding: 16px !important;
    }
  }
}

.blink-input-inline {
  width: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: var(--ion-color-input);
  border: none;
  outline: none;
  padding: 8px;
}


.blink-required:after {
  content: '*';
  color: var(--ion-color-danger);
  font-size: 1.8rem;
  position: absolute;
  margin-top: -4px;
  margin-left: 2px;
}

[required] .label-text:after {
  content: '*';
  color: var(--ion-color-danger);
  font-size: 1.8rem;
  position: absolute;
  margin-top: -4px;
  margin-left: 2px;
}

ion-radio-group ion-item:first-of-type {
  border-radius: 0.3rem 0.3rem 0 0;
}

small {
  color: var(--ion-color-medium-shade);
}


// oc items

ion-button {

  font-size: 14px !important;
  line-height: 14px !important;
  font-weight: 400 !important;
  letter-spacing: normal !important;

  ion-badge {
    position: absolute;
    top: 2px;
    right: -12px;
    padding: 2px 2px 2px 4px;
  }
}

.blink-sort-vertical {
  cursor: row-resize;
}


.plt-desktop .scan-code-image {
  width: 80%;
  margin-bottom: 30px;
}


.hide-button-text {
  font-size: 0 !important;

  i, ui-icon {
    font-size: initial !important;
    margin: 0 13px !important;
  }
}

//p.line-around {
//  display: flex;
//  justify-content: center;
//  overflow: hidden;
//  text-align: center;
//
//  &:after, &:before {
//    background-color: #000;
//    content: "";
//    display: inline-block;
//    height: 1px;
//    position: relative;
//    vertical-align: middle;
//    width: 50%;
//    top: 0.5rem;
//  }
//
//  &:before {
//    right: 0.5em;
//    margin-left: -50%;
//  }
//
//  &:after {
//    left: 0.5em;
//    margin-right: -50%;
//  }
//}

.text-primary {
  color: var(--ion-color-primary);
}

.text-success {
  color: var(--ion-color-success);
}

.text-danger {
  color: var(--ion-color-danger);
}

.text-black {
  color: black;
}

.text-italic {
  font-style: italic;
}

ion-segment {
  --background: transparent;
  border: 1px solid var(--ion-color-primary);
}

ion-segment-button {
  text-transform: none;
  min-height: 36px;

  --background-checked: var(--ion-color-primary);
  --indicator-color: var(--ion-color-primary);
  --color-checked: black;
  margin-top: 0;
  margin-bottom: 0;

  i, ui-icon {
    color: black;
  }

  &.segment-button-checked {

    i, ui-icon {
      color: black;
    }
  }
}

.input-clear-icon.sc-ion-input-md {
  width: 40px;
  background-size: 15px;
}

.no-padding-left {
  --padding-start: 0;
}

small {
  font-size: 13px !important;
  display: block;
  padding-top: 5px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.grid-table {
  ion-row:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
}

.list-remove-borders {

  ion-item {
    --border-color: transparent;
  }
}

.borders-horizontal {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.pre-wrap {
  white-space: pre-wrap;
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  width: 100%
}

ion-modal.modal-overflow-visible {
  .ion-page {
    overflow: inherit;
  }

  &::part(content) {
    overflow: visible;
  }
}

.radio-item-input {
  z-index: 3;
}

.cursor-pointer {
  cursor: pointer;
}
