ion-footer.core-page {
  box-shadow: none;
  border-radius: var(--wrapper-border-radius) var(--wrapper-border-radius) 0 0;
  background-color: white;
  border-top: 1px solid var(--color-background);

  ion-toolbar {
    --background: white;
  }
}
