﻿
.cursor-pointer {
  cursor: pointer;
}

.ellipsis-nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.text-5xl {
  font-size: 3rem; /* 48px */
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}

.text-8xl {
  font-size: 6rem; /* 96px */
  line-height: 1;
}

.text-9xl {
  font-size: 8rem; /* 128px */
  line-height: 1;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.text-white {
  color: #ffffff !important;
}

.text-black {
  color: #000000;
}

.text-gray {
  color: #808080;
}

.text-primary {
  color: var(--ion-color-primary);
}

.text-planning {
  color: var(--blink-color-planning-future);
}

.text-success {
  color: var(--ion-color-success);
}

.text-danger {
  color: var(--ion-color-danger);
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

ion-header.no-border ion-toolbar {
  border-bottom: 0;
}

.display-none {
  display: none;
}

.block {
  display: block;
}

.hide {
  display: none;
}

.w-full {
  width: 100%;
}

.border-top {
  border-top: 1px solid var(--color-navigation-border);
}

.border-left {
  border-left: 10px solid var(--color-secondary-georg) !important;

  &--gray {
    // default
  }
  &--red {
    border-left-color: var(--ion-color-danger) !important;
  }
  &--orange {
    border-left-color: orange !important;
  }
  &--blue {
    border-left-color: var(--ion-color-tertiary) !important;
  }
}
