:root {
  --color-white: white;
  --color-background: #f2f1f6;
  --color-navigation-border: #d7d7d7;
  --color-divider: #BDBDBD;
  --color-secondary: #9E9E9E;
  --color-secondary-georg: #808080;

  --blink-color-planning-future: #0060AE;
  --blink-color-planning-past: red;
  --blink-color-workload: #D1D1D1;
  --blink-color-absence: #ffc409;
  --blink-color-worklog: #70AF1A;


  --blink-color-green: #70AF1A;
  --blink-color-blue: #0060AE;

  --color-gray-background: #F1F1F5;

  --wrapper-border-radius: 10px;

  --item-border-color: #e2e2e2;
  --item-border-radius: 6px;
  --item-background-hover: #f9f8fb;

  --box-shadow-hover: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);

  --input-border-color: #c3c3c3;

  --smooth-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  --ion-background-color: var(--color-background);
  --ion-toolbar-background: var(--color-white);
  --ion-item-background: var(--color-white);
}
