ion-header {
  &.header-md {
    box-shadow: none;

    &::after {
      bottom: -1px;
      height: 1px;
      background: transparent;
    }
  }

  //ion-toolbar {
  //  border-bottom: 1px solid var(--navigation-border-color);
  //}

  ion-title {
    color: black;
    font-weight: 500;
    padding-inline: 16px;
  }

  ion-button.button-solid {
    --border-radius: 4px !important;
  }
}
