* {
  --default-box-shadow: 0; // rgb(0 0 0 / 8%) 0px 3px 1px -2px,
  //rgb(0 0 0 / 5%) 0px 2px 2px 0px,
  //rgb(0 0 0 / 4%) 0px 1px 5px 0px;
}

ion-card {
  box-shadow: var(--default-box-shadow)
}


ion-menu.show-menu {
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.09);
}

ion-toast::part(button) {
  text-transform: none !important;
}

//img {
//   border-radius: 5px;
// }

.popover-datetime::part(content) {
  min-width: 280px;
}


ion-list.list-md {
  background: transparent;
  padding: 0;
  border-radius: var(--wrapper-border-radius);
}

//@container (min-width: )

.flex-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
