﻿
.normal-modal {
  --height: fit-content;
  padding-right: 15px;
  padding-left: 15px;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --max-width: 450px;

  ion-header ion-toolbar:first-of-type {
    padding-top: 0;
  }
}
