ion-modal {
  --box-shadow: var(--default-box-shadow);
  // android fix
  color: black;

  --background: var(--color-white);
  --border-radius: var(--wrapper-border-radius);

  .ion-page {
    border-radius: var(--wrapper-border-radius);
    overflow: hidden !important;
  }

  ion-toolbar {
    //--border-width: 0 0 1px 0;
    //--border-color: var(--color-navigation-border);
    border-bottom: none;
  }

  ion-content {
    --color-background: var(--color-white);
  }

  ion-footer.footer-md {
    box-shadow: none;
    //border-top: 1px solid var(--color-navigation-border);
    border-top: none;
  }


  &.ui-modal-auto-height,
  &.modal-auto-height {
    --height: auto;

    // ios fix
    ion-header ion-toolbar:first-of-type {
      padding-top: 0;
    }

    .ion-page {
      position: relative;
      display: block;

      .modal-content {
        max-height: 80vh;
        overflow: auto;
      }
    }
  }

  &.ui-modal-small {
    --width: 350px;
  }

  &.ui-modal-full {
    --width: 100%;
    --height: 100%;
  }

  &.ui-modal-big {
    --width: 60%;
    --height: 100%;
    --max-width: 1200px;
  }

  &.ui-modal-backdrop {
    --box-shadow: var(--default-box-shadow) !important;
  }
}
