/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//fontawesome
@import "~@fortawesome/fontawesome-pro/css/fontawesome.min.css";
@import "~@fortawesome/fontawesome-pro/css/regular.css";
@import "~@fortawesome/fontawesome-pro/css/light.css";
@import "~@fortawesome/fontawesome-pro/css/solid.css";

// Ionic Customizations
@import 'ionic-variables';
@import 'ion-button';
@import 'ion-header';
@import 'ion-footer';
@import 'ion-modal';
@import 'ion-item';
@import 'ion-spinner';

// Blink Ui
@import 'blink-ui-random';
@import 'blink-ui-modal';
@import 'blink-ui-spacing';
@import 'blink-ui-select';
@import 'blink-ui-utility';
@import 'blink-ui-variables';
@import 'blink-ui-text';
@import 'blink-ui-input';
@import "../ui-tooltip/tooltips.css";

// important for barcodescanner
body {
  background: transparent !important;
}

.required:after {
  content: '*';
  color: var(--ion-color-danger);
  font-size: 1.8rem;
  position: absolute;
  margin-top: -4px;
  margin-left: 2px;
}

.circle-40 {
  width: 40px;
  height: 40px;
  border-radius: 100%;

  background-color: var(--ion-color-primary);
  color: black;

  text-align: center;
  padding-top: 12px;
}


ion-badge.list-badge {
  --background: #f2f1f6;
  color: #808080;
}
