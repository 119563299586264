ion-item:last-child .item-native {
  border-bottom: none !important;
}

ion-item {
  &[color="input"] {
    &::part(native) {
      background-color: white;
      border: 1px solid var(--item-border-color);
      border-radius: var(--item-border-radius);
    }
  }

  ui-icon[slot="start"] {
    margin-inline-end: 16px;
  }
}
