@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/assets-ui/fonts/roboto-latin-400-normal.woff2')
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url('/assets-ui/fonts/roboto-latin-500-normal.woff2')
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url('/assets-ui/fonts/roboto-latin-700-normal.woff2')
}


h3 {
  color: black;
}

.text-formatted {
   white-space: pre-wrap;
 }

.text-primary {
  color: var(--ion-color-primary);
}

.text-secondary {

}

