input {
  color: var(--ion-color-dark) !important;
}

.blink-item-input {
  --background: var(--ion-color-input);
  --inner-padding-end: 0;
  --padding-start: 0;
  --inner-padding-start: 16px;
  --inner-padding-top: 5px;
  --inner-padding-bottom: 5px;

  --min-height: 65px;

  ion-input {
    --highlight-color-focused: black;
    --highlight-color-valid: black;


    label, ion-label {
      color: #757575 !important;
    }
  }

  .input-select-action-button {
    margin: auto;

    ui-icon {
      color: rgba(0, 0, 0, 0.54) !important;
      transition: transform 150ms ease-out;
    }
  }

  overflow: visible;

  &.is-open {
    z-index: 999;

    .dropdown-chevron {
      transform: rotate(180deg);
    }
  }
}

.required:after {
  content: '*';
  color: var(--ion-color-danger);
  font-size: 1.8rem;
  position: absolute;
  margin-top: -4px;
  margin-left: 2px;
}

//ui-card ui-input .ui-input-wrapper {
//  margin-bottom: 0 !important;
//}

ion-app:not(.keyboard-visible) {
  .ui-mobile-tabs-active {
    @media(max-width: 992px) {
      bottom: 58px !important;
    }
  }
}

ion-app.keyboard-visible {
  ui-mobile-tabs,
  ion-footer {
    display: none;
  }

}

input::-webkit-calendar-picker-indicator {
  display: none;
}
